import React from "react";
import { default as MUITooltip } from "@mui/material/Tooltip";

export interface TooltipProps {
  title: React.ReactNode;
  children: React.ReactElement;
  arrow?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export function Tooltip({ title, arrow = true, placement = "top", children }: TooltipProps) {
  return (
    <MUITooltip
      title={title}
      placement={placement}
      arrow={arrow}
      classes={{
        tooltip: "bg-background-idle text-text-primary px-s py-xs",
        arrow: "text-background-idle",
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      {children}
    </MUITooltip>
  );
}
