import React from "react";
import type { INavigationItem } from "shared/types";
import { NavLink } from "react-router-dom";
import { ListItem, Tooltip } from "@mui/material";
import styles from "./NavigationItem.module.css";

export const NavigationItem = ({
  disabled,
  path,
  label,
  "icon": Icon,
  "data-testid": testId,
}: INavigationItem) => {
  return (
    <Tooltip title={label} placement="right">
      <NavLink to={path} data-testid={testId}>
        {({ isActive }) => (
          <ListItem
            disabled={disabled}
            className={styles.NavigationItem__ListItem}
            data-selected={isActive}
          >
            <Icon className="text-interactive-positive" />
          </ListItem>
        )}
      </NavLink>
    </Tooltip>
  );
};
