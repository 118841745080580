import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import { createRoot } from "react-dom/client";
import classnames from "classnames";

export interface LimitedListProps<T> {
  items: T[];
  rowsLimit: number;
  renderItem: (item: T) => JSX.Element;
  renderMore: (itemsNotRendered: T[]) => JSX.Element;
  maxWidth: number;
  className?: string;
}

export function LimitedList<T>({
  items,
  rowsLimit = 2,
  renderItem,
  renderMore,
  maxWidth,
  className,
}: LimitedListProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const calculationState = useRef<{
    shouldCalculate: boolean;
    lastOk: [number, boolean];
    allItemsRenderedTested: boolean;
  }>({
    shouldCalculate: true,
    lastOk: [1, true], // [visibleItemsCount, showMore]
    allItemsRenderedTested: false,
  });
  const dimensions = useRef({ oneRowHeight: 0, twoRowsHeight: 0 });

  const [visibleItemsCount, setVisibleItemsCount] = useState<number>(1);
  const [showMore, setShowMore] = useState<boolean>(items.length > 0);

  const listClassNames = classnames(
    "flex flex-row flex-wrap items-center",
    className,
    `max-w-[${maxWidth}px]`
  );

  useEffect(() => {
    calculationState.current = {
      shouldCalculate: items.length > 0,
      lastOk: [1, true],
      allItemsRenderedTested: false,
    };
    setVisibleItemsCount(1);
    setShowMore(items.length > 0);
  }, [rowsLimit, items]);

  useLayoutEffect(() => {
    if (!calculationState.current.shouldCalculate) {
      return;
    }

    const checkIfFits = () => {
      const height = containerRef.current?.offsetHeight || 0;

      if (!dimensions.current.oneRowHeight) {
        // Set the one row height when we render the first item
        dimensions.current.oneRowHeight = height;
      }
      if (
        dimensions.current.oneRowHeight &&
        !dimensions.current.twoRowsHeight &&
        height > dimensions.current.oneRowHeight
      ) {
        // Set the height of two rows
        dimensions.current.twoRowsHeight = height;
      }

      if (
        height <= dimensions.current.oneRowHeight ||
        (height <= dimensions.current.twoRowsHeight && rowsLimit === 2)
      ) {
        return true;
      }

      const maxAllowedHeight =
        (rowsLimit - 1) * (dimensions.current.twoRowsHeight - dimensions.current.oneRowHeight) +
        2 /** Multiply by 2 to account for an error margin, as the height may vary slightly. */ *
          dimensions.current.oneRowHeight;

      return height <= maxAllowedHeight;
    };

    const itFits = checkIfFits();

    if (!itFits) {
      if (!calculationState.current.allItemsRenderedTested) {
        setVisibleItemsCount(items.length);
        setShowMore(false);
        calculationState.current.allItemsRenderedTested = true;
      } else {
        setVisibleItemsCount(calculationState.current.lastOk[0]);
        setShowMore(calculationState.current.lastOk[1]);
        calculationState.current.shouldCalculate = false;
      }
    } else {
      if (calculationState.current.allItemsRenderedTested) {
        // This check is the last one, so if we are here we can render everything and we can stop.
        calculationState.current.shouldCalculate = false;

        return;
      }

      calculationState.current.lastOk = [visibleItemsCount, showMore];

      if (visibleItemsCount === items.length) {
        setVisibleItemsCount(items.length);
        setShowMore(false);
        calculationState.current.allItemsRenderedTested = true;
      } else {
        setVisibleItemsCount((oldValue) => oldValue + 1);
      }
    }
  }, [visibleItemsCount, showMore, items, rowsLimit]);

  return (
    <div className={listClassNames} ref={containerRef}>
      {items.slice(0, visibleItemsCount).map((item, index) => (
        <div key={index}>{renderItem(item)}</div>
      ))}
      {showMore && <>{renderMore(items.slice(visibleItemsCount))}</>}
    </div>
  );
}
