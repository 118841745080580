// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationItem-module__NavigationItem__ListItem--fss52 {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: var(--interactive-positive);
    font-size: 20px;
  }

  &[data-selected="true"],
  &:hover,
  &:focus {
    background: var(--background-active);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/layout/molecules/NavigationItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB;IACE,kCAAkC;IAClC,eAAe;EACjB;;EAEA;;;IAGE,oCAAoC;EACtC;AACF","sourcesContent":[".NavigationItem__ListItem {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    color: var(--interactive-positive);\n    font-size: 20px;\n  }\n\n  &[data-selected=\"true\"],\n  &:hover,\n  &:focus {\n    background: var(--background-active);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItem__ListItem": `NavigationItem-module__NavigationItem__ListItem--fss52`
};
export default ___CSS_LOADER_EXPORT___;
