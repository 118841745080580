import { RemoveCircleOutlinedIcon } from "assets/icons";
import React, { ComponentProps } from "react";
import { SensitivityBars } from "ui";
import { Sensitivity } from "ui/types";

export const NULL_SENSITIVITY = -1;

export type SensitivityItemData = {
  label: string;
  sensitivity: Sensitivity;
  isNullItem?: boolean;
  color: string;
};

export const sensitivityMap: Record<Sensitivity | "null_value", SensitivityItemData> = {
  [Sensitivity.Unrestricted]: {
    label: "Unrestricted",
    sensitivity: Sensitivity.Unrestricted,
    color: "var(--severity-informational)",
  },
  [Sensitivity.Low]: {
    label: "Low",
    sensitivity: Sensitivity.Low,
    color: "var(--semantic-warning-primary)",
  },
  [Sensitivity.Moderate]: {
    label: "Moderate",
    sensitivity: Sensitivity.Moderate,
    color: "var(--severity-medium)",
  },
  [Sensitivity.High]: {
    label: "High",
    sensitivity: Sensitivity.High,
    color: "var(--severity-high)",
  },
  [Sensitivity.Critical]: {
    label: "Critical",
    sensitivity: Sensitivity.Critical,
    color: "var(--severity-critical)",
  },
  null_value: {
    label: "Undefined",
    sensitivity: NULL_SENSITIVITY as Sensitivity,
    color: "",
    isNullItem: true,
  },
};

export const DEFAULT_SENSITIVITY = Sensitivity.Low;

export const sensitivityListWithNull = Object.values(sensitivityMap)
  .map(({ label, sensitivity, ...rest }) => {
    return { sensitivity, label, ...rest };
  })
  .sort((a, b) => b.sensitivity - a.sensitivity);

export const sensitivityList = sensitivityListWithNull.filter(({ isNullItem }) => !isNullItem);

export const isNullSensitivityAllowed = (sensitivities: Sensitivity[] | null) => {
  if (!sensitivities) {
    return false;
  }
  return sensitivities.includes(NULL_SENSITIVITY as Sensitivity);
};

export const isAllSensitivitiesSelected = (sensitivities?: Sensitivity[] | null) => {
  if (!sensitivities) {
    return false;
  }
  if (sensitivities.length === 0) {
    return true;
  }
  const severitiesSet = new Set(sensitivities);
  return sensitivityList.every((v) => severitiesSet.has(v.sensitivity));
};

export type SensitivityItemProps = {
  containerProps?: ComponentProps<"div">;
  sensitivity: Sensitivity;
  label?: string | null;
  isNullItem?: boolean;
};

export const SensitivityItem = ({
  containerProps,
  sensitivity,
  label: labelProps = null,
  isNullItem = false,
}: SensitivityItemProps) => {
  if (!sensitivityMap[sensitivity] && !isNullItem) {
    return null;
  }
  const { style, ...restContainerProps } = containerProps || {};
  const { label } = sensitivityMap[sensitivity] || sensitivityMap.null_value;
  return (
    <div
      style={{ display: "flex", alignItems: "center", padding: "3px 0", ...style }}
      {...restContainerProps}
    >
      {!isNullItem ? <SensitivityBars barsCount={sensitivity} /> : <RemoveCircleOutlinedIcon />}
      <span style={{ marginLeft: 8 }}>{labelProps || label}</span>
    </div>
  );
};
