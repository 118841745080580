import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTimeout } from "shared/useTimeout";
import { CopyGreyIcon } from "assets/icons";
import { notification } from "modules/notification";
import { messages } from "ui/messages";
import { theme } from "ui/theme";
import { IconButton } from "ui/atoms/IconButton";
import { Text } from "ui/atoms/Text";
import { CssPropType } from "ui/atoms/Box";
import { VStack, HStack } from "ui/atoms/Stack";
import { ShowOnHover, ShowOnHoverProps } from "ui/atoms/ShowOnHover";
import { css } from "ui/css";

type CopyTextToClipboardProps = Omit<Omit<ShowOnHoverProps, "icons">, "node"> & {
  onSuccess?: () => void;
  iconStyle?: React.CSSProperties;
  inlineSuccessSx?: CssPropType;
  iconSize?: "inherit" | "medium" | "small" | "large";
  size?: "small" | "medium" | "large";
  showIcon?: boolean; // temporary where we allow to select text but dont show copy button
  showIconAlways?: boolean; // disable on hover functionality and show copy button at all times
  text?: string;
  className?: string;
  spacer?: boolean;
  clickable?: boolean;
  hightlightOnHover?: boolean;
  showInlineSuccess?: boolean;
  IconComponent?: React.ElementType;
  testId?: string;
};

const iconClass = css({
  padding: 0,
  paddingBottom: 4,
  paddingLeft: 2,
  variants: {
    hightlightOnHover: {
      true: {
        "&:hover": { color: theme.palette.primary.main },
      },
    },
  },
});

export const onCopySuccess = () =>
  notification.success({
    message: messages.copyTooltip.copiedText,
  });

export const CopyTextToClipboard = ({
  children,
  space,
  sx,
  className,
  inlineSuccessSx,
  showIcon = false,
  showIconAlways = false,
  hightlightOnHover = false,
  spacer = false,
  iconStyle = {},
  iconSize = "small",
  size = "large",
  text = "",
  showInlineSuccess = false,
  onSuccess = onCopySuccess,
  IconComponent,
  testId,
}: CopyTextToClipboardProps) => {
  const parentElement = useRef<HTMLDivElement>(null);
  const [innerText, setInnerText] = useState(text);
  const [isShowInlineSuccess, setShowInlineSuccess] = useState(false);
  useEffect(() => {
    setInnerText(text || parentElement.current?.innerText || "");
  }, [text, parentElement.current?.innerText]);
  const { setTimeout } = useTimeout();

  const onCopy = () => {
    if (showInlineSuccess) {
      setShowInlineSuccess(true);
      setTimeout(() => setShowInlineSuccess(false), 3000);
    }
    onSuccess();
  };
  const icon = (
    <CopyToClipboard text={innerText} onCopy={onCopy}>
      <IconButton
        className={iconClass({ hightlightOnHover }).className}
        style={{
          ...iconStyle,
        }}
        title={messages.copyTooltip.copy}
        size={size}
        data-testid={testId}
      >
        {IconComponent ? <IconComponent /> : <CopyGreyIcon fontSize={iconSize} />}
      </IconButton>
    </CopyToClipboard>
  );

  return (
    <ShowOnHover
      ref={parentElement}
      node={showIcon && !isShowInlineSuccess && icon}
      sx={{
        userSelect: "text",
        backgroundColor: isShowInlineSuccess ? "rgba(67, 167, 234, 0.2);" : "",
        ...sx,
      }}
      className={className}
      space={space}
      spacer={spacer}
      disableOnHover={showIconAlways}
    >
      {isShowInlineSuccess ? (
        <VStack align={"center"} sx={inlineSuccessSx} fullWidth>
          <HStack align={"center"} sx={{ height: "100%" }}>
            <Text>{messages.copyTooltip.copiedText}</Text>
          </HStack>
        </VStack>
      ) : (
        children
      )}
    </ShowOnHover>
  );
};
