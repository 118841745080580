import React, { useEffect } from "react";
import { PermissionsProvider } from "shared/permissions";
import { useRoutes } from "react-router";
import { FullscreenContent } from "modules/layout";
import { ProtectedRoute } from "modules/router";
import { TimezoneProvider } from "modules/timezone";
import { HomePage, NotFound } from "modules/router/pages";
import { Route, routesArray } from "./routes";

function RouteWrapper({ element, route }: { route: Route; element: JSX.Element }): JSX.Element {
  let LayoutWrapper = route.layout;

  LayoutWrapper = LayoutWrapper ?? React.Fragment;

  useEffect(() => {
    document.body.setAttribute(
      "data-support-dark-theme",
      route.supportsDarkTheme ? "true" : "false"
    );

    return () => {
      if (route.supportsDarkTheme) {
        document.body.removeAttribute("data-support-dark-theme");
      }
    };
  }, [route.supportsDarkTheme]);

  const wrappedChildren = LayoutWrapper ? (
    <LayoutWrapper {...route.layoutProps}>{element}</LayoutWrapper>
  ) : (
    element
  );
  if (!route.requiredPermissions && !route.requiredPermissionsAnyOf) {
    return wrappedChildren;
  }

  return (
    <PermissionsProvider>
      <LayoutWrapper {...route.layoutProps}>
        <ProtectedRoute
          requiredPermissions={route.requiredPermissions}
          requiredPermissionsAnyOf={route.requiredPermissionsAnyOf}
        >
          <TimezoneProvider>{element}</TimezoneProvider>
        </ProtectedRoute>
      </LayoutWrapper>
    </PermissionsProvider>
  );
}

export const Router = () => {
  const element = useRoutes([
    {
      path: "/",
      index: true,
      element: <HomePage />,
    },
    ...routesArray.map((route) => ({
      path: route.path,
      element: <RouteWrapper route={route} element={React.createElement(route.component)} />,
      children: route.children?.map((route) => ({
        path: route.path,
        index: route.index,
        element: <RouteWrapper route={route} element={React.createElement(route.component)} />,
      })),
    })),
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return <FullscreenContent>{element}</FullscreenContent>;
};
