// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader-module__bar--l71mb {
  animation: Loader-module__scaleUpDown--kAOi_ 1s ease-in-out infinite;
  transform-origin: bottom;
  animation-delay: var(--delay);
  transform: scaleY(1) translateY(16px);
}

@keyframes Loader-module__scaleUpDown--kAOi_ {
  0%,
  100% {
    transform: scaleY(1) translateY(16px);
  }
  50% {
    transform: scaleY(0.25) translateY(16px); /* Translate down by half the height of the bar */
  }
}
`, "",{"version":3,"sources":["webpack://./src/ds-ui/molecules/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,oEAA8C;EAC9C,wBAAwB;EACxB,6BAA6B;EAC7B,qCAAqC;AACvC;;AAEA;EACE;;IAEE,qCAAqC;EACvC;EACA;IACE,wCAAwC,EAAE,iDAAiD;EAC7F;AACF","sourcesContent":[".bar {\n  animation: scaleUpDown 1s ease-in-out infinite;\n  transform-origin: bottom;\n  animation-delay: var(--delay);\n  transform: scaleY(1) translateY(16px);\n}\n\n@keyframes scaleUpDown {\n  0%,\n  100% {\n    transform: scaleY(1) translateY(16px);\n  }\n  50% {\n    transform: scaleY(0.25) translateY(16px); /* Translate down by half the height of the bar */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `Loader-module__bar--l71mb`,
	"scaleUpDown": `Loader-module__scaleUpDown--kAOi_`
};
export default ___CSS_LOADER_EXPORT___;
