export const THEME_ATTR = "data-color-scheme";

export const selectDefaultTheme = () => document.body?.setAttribute(THEME_ATTR, "light");
export const selectDarkTheme = () => document.body?.setAttribute(THEME_ATTR, "dark");

export const applyTheme = (theme = "system") => {
  const themeToSet =
    theme === "system"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : theme;
  document.body?.setAttribute(THEME_ATTR, themeToSet);
};
