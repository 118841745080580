// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleButtonGroup-module__ToggleButtonGroup__Button--LMvY9 {
  outline-width: 0 !important;
  border: none !important;

  &:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/ds-ui/molecules/ToggleButtonGroup.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,uBAAuB;;EAEvB;IACE,oCAAoC;IACpC,uCAAuC;EACzC;;EAEA;IACE,qCAAqC;IACrC,wCAAwC;EAC1C;AACF","sourcesContent":[".ToggleButtonGroup__Button {\n  outline-width: 0 !important;\n  border: none !important;\n\n  &:not(:first-child) {\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n  }\n\n  &:not(:last-child) {\n    border-top-right-radius: 0 !important;\n    border-bottom-right-radius: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToggleButtonGroup__Button": `ToggleButtonGroup-module__ToggleButtonGroup__Button--LMvY9`
};
export default ___CSS_LOADER_EXPORT___;
