import React from "react";
import { fixedForwardRef } from "shared";
import { SvgIconComponent } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material/SvgIcon";
import * as MaterialIcons from "./material-icons";
import * as CustomIcons from "./custom-icons";

export const iconSizes = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 48,
  xxl: 96,
};

export type IconName = keyof typeof MaterialIcons | keyof typeof CustomIcons;

export type IconSize = keyof typeof iconSizes;

export const iconSizesKeys = Object.keys(iconSizes).reduce(
  (acc: Record<keyof typeof iconSizes, keyof typeof iconSizes>, key) => {
    acc[key as keyof typeof iconSizes] = key as keyof typeof iconSizes;
    return acc;
  },
  {} as Record<keyof typeof iconSizes, keyof typeof iconSizes>
);

export type IconProps = SvgIconProps & {
  name: IconName;
  size?: IconSize;
  disabled?: boolean;
};

export const Icon = fixedForwardRef(function Icon(
  { name, size: sizeProp = "l", style, ...rest }: IconProps,
  ref: React.Ref<SVGSVGElement>
) {
  const Icon = ((MaterialIcons as any)[name] || (CustomIcons as any)[name]) as SvgIconComponent;
  const size = iconSizes[sizeProp];
  return (
    <Icon style={{ width: size, height: size, ...style }} data-testid={name} {...rest} ref={ref} />
  );
});
