import React from "react";
import { fixedForwardRef } from "shared";
import { VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from "@mui/material";
import { ButtonStyles } from "./Button.styles";

export type ToggleButtonProps = MuiToggleButtonProps &
  VariantProps<typeof ButtonStyles> & {
    className?: string;
    beforeIcon?: React.ReactElement;
    afterIcon?: React.ReactElement;
    testId: string;
  };

export const ToggleButton = fixedForwardRef(function ToggleButton({
  className,
  weight,
  tone,
  size,
  disabled,
  testId,
  ...props
}: ToggleButtonProps) {
  return (
    <MuiToggleButton
      disableRipple
      data-testid={testId}
      {...props}
      className={twMerge(ButtonStyles({ className, weight, tone, size, disabled }))}
    />
  );
});
