import React from "react";
import { VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { fixedForwardRef } from "shared";
import { iconSizesKeys } from "ds-ui/icons/Icon";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/material";
import { ButtonStyles } from "./Button.styles";

export type ButtonProps = Pick<
  MUIButtonProps,
  "id" | "href" | "children" | "onClick" | "title" | "style"
> &
  VariantProps<typeof ButtonStyles> & {
    className?: string;
    beforeIcon?: React.ReactElement;
    afterIcon?: React.ReactElement;
    testId: string;
  };

export const Button = fixedForwardRef(function CHButton(
  {
    weight = "primary",
    className,
    title,
    tone = "positive",
    size,
    disabled,
    beforeIcon,
    afterIcon,
    testId,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <MUIButton
      ref={ref}
      disableRipple
      disabled={!!disabled}
      sx={{ border: "transparent" }}
      data-testid={testId}
      className={twMerge(ButtonStyles({ weight, className, tone, size, disabled: disabled }))}
      {...props}
    >
      {beforeIcon && (
        <div className={"flex " + (size === "s" ? "mr-xs" : "mr-s")}>
          {React.cloneElement(beforeIcon, {
            // Conditionally add the `size` prop only if it's not already specified
            ...(beforeIcon.props.size === undefined && {
              size: size === "l" ? iconSizesKeys.l : iconSizesKeys.s,
            }),
          })}
        </div>
      )}
      {props.children}
      {afterIcon && (
        <div className={"flex " + (size === "s" ? "ml-xs" : "ml-s")}>
          {React.cloneElement(afterIcon, {
            // Conditionally add the `size` prop only if it's not already specified
            ...(afterIcon.props.size === undefined && {
              size: size === "l" ? iconSizesKeys.l : iconSizesKeys.s,
            }),
          })}
        </div>
      )}
    </MUIButton>
  );
});
