import { useEffect } from "react";
import { useLocalStorage } from "react-use";
import {
  ThemeOption,
  useUpdateUserPreferneces,
  useUserPreferences,
} from "shared/user-preferences/userPreferences";
import { applyTheme } from "../../ds-ui/theme";

export function useTheme() {
  const [colorTheme, setColorTheme] = useLocalStorage("ColorTheme", "system");
  const { data, isLoading } = useUserPreferences();
  const themeOption = data?.settings?.theme;

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferneces();

  const updateTheme = async (theme: ThemeOption) => {
    setColorTheme(theme);
    await updateUserPreferences({ settings: { ...data?.settings, theme } });
  };

  // Update the theme based on user preferences
  // The user might have changed this on another machine
  useEffect(() => {
    if (themeOption && themeOption !== colorTheme) {
      setColorTheme(themeOption);
      applyTheme(themeOption);
    }
  }, [themeOption]);

  useEffect(() => {
    if (colorTheme && colorTheme !== "system") {
      applyTheme(colorTheme);
    } else {
      applyTheme(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    }
  }, [colorTheme]);

  return { updateTheme, colorTheme, isLoading };
}
